<template>
  <div>
    <div v-if="time">
      <v-menu
        ref="time_menu"
        v-model="time_menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="new_time"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="timeFormatted"
            :label="label ? label : 'Time'"
            append-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
            @blur="new_time = timeFormatted"
            :rules="rules"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="time_menu"
          v-model="new_time"
          full-width
          @click:minute="$refs.time_menu.save(new_time)"
          :ampmInTitle="true"
        ></v-time-picker>
      </v-menu>
    </div>

    <!--Date Picker-->
    <div v-if="date">
      <v-menu
        ref="date_menu"
        v-model="date_menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateFormatted"
            :label="label ? label : 'Date'"
            hint="MM-DD-YYYY"
            persistent-hint
            append-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            @blur="new_date = parseDate(dateFormatted)"
            :rules="rules"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="new_date"
          :min="minDate"
          :max="maxDateRange"
          no-title
          @input="date_menu = false"
          @change="dateSelected"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateTime",
  props: [
    "time",
    "timeValue",
    "date",
    "dateValue",
    "reset",
    "label",
    "rules",
    "minDateRange",
    "maxDateRange",
    "useCurDate",
  ],
  data() {
    return {
      time_menu: false,
      new_time: "",
      date_menu: false,
      new_date: new Date().toISOString().substr(0, 10),
      dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
      timeFormatted: "",
      minDate: this.minDateRange,
    };
  },
  created() {
    if (this.timeValue) {
      this.timeFormatted = this.timeValue;
    }
    if (this.dateValue) {
      this.dateFormatted = this.dateValue;
    } else {
      this.dateFormatted = "";
    }
  },

  watch: {
    new_date() {
      if (this.new_date !== "") {
        this.dateFormatted = this.formatDate(this.new_date);
        this.emitData();
      }
    },
    new_time() {
      if (this.new_time !== "") {
        this.timeFormatted = this.formatAMPM(this.new_time);
        this.emitData();
      }
    },
    reset(val) {
      if (val) {
        this.new_date = "";
        this.new_time = "";
        this.timeFormatted = "";
        this.dateFormatted = "";
        this.emitData();
      }
    },
    timeValue(val) {
      this.timeFormatted = val;
    },
    dateValue(val) {
      this.dateFormatted = val;
    },
    minDateRange(val) {
      let date = new Date(val);
      date = date.setDate(date.getDate() + 1);
      this.minDate = new Date(date).toISOString().substr(0, 10);
    },
  },
  methods: {
    dateSelected() {
      this.$emit("selectedDate", this.new_date);
    },
    formatAMPM(time) {
      let hours_mins = time.split(":");
      let hours = parseInt(hours_mins[0]);
      let minutes = parseInt(hours_mins[1]);
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}${month.padStart(2, "0")}-${day.padStart(2, "0")}-`;
    },
    emitData() {
      if (this.time) {
        this.$emit("input", this.timeFormatted);
      }
      if (this.date) {
        this.$emit("input", this.dateFormatted);
      }
    },
  },
};
</script>

<style scoped></style>
